<template>
  <section>
    <v-container fluid class="pa-5">
      <v-row class="font-weight-bold" align="center">
        <v-col cols="6">{{ $t('current financial year') }}</v-col>
        <v-col cols="6" class="text-end">
          <!-- :exact-active-class="false" :exact-path="false" :exact="false"  -->
          <!-- <v-btn depressed color="blue11" class="primary--text mx-2" width="105"  :to="`/${pageData.controlRoute}`">{{ $t('edit') }}</v-btn> -->
          <v-btn depressed color="blue12" class="primary--text mx-2" width="105" @click="edit">{{ $t('edit') }}</v-btn>
          <v-btn depressed color="error" class="white--text" width="147" @click="cancellationDialog = true">
            {{ $t('cancel the year') }}</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" v-if="!financeYear?.finance_year_from && financeYear?.finance_year_to">
          <AnimatedLoading classes="pt-2 pa-5"
            :isLoading="!financeYear?.finance_year_from && financeYear?.finance_year_to" height="100" :hideText="false" />
        </v-col>
      </v-row>

      <v-row align="center" v-if="financeYear?.finance_year_from && financeYear?.finance_year_to">
        <v-col cols="12" class="text-body-2">{{ $t('current year') }}</v-col>
        <v-col cols="6" class="d-flex align-center" :key="refresher">
          <span class="card_year">
            <div class="error--text">{{ dateInfo(financeYear?.finance_year_from).letterDay }}</div>
            <div class="font-weight-bold text-h5">{{ dateInfo(financeYear?.finance_year_from).day }}</div>
            <div>{{ dateInfo(financeYear?.finance_year_from).letterMonth }}</div>
            <div class="gray6--text">{{ dateInfo(financeYear?.finance_year_from).year }}</div>
          </span>
          <span class="mx-6">-</span>
          <span class="card_year">
            <div class="error--text">{{ dateInfo(financeYear?.finance_year_to).letterDay }}</div>
            <div class="font-weight-bold text-h5">{{ dateInfo(financeYear?.finance_year_to).day }}</div>
            <div>{{ dateInfo(financeYear?.finance_year_to).letterMonth }}</div>
            <div class="gray6--text">{{ dateInfo(financeYear?.finance_year_to).year }}</div>
          </span>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <div class="mx-7">
            <span class="text-caption">{{ $t('number of working days') }}</span>
            <div class="font-weight-bold text-h5 mt-2">{{ financeYear?.nof_working_days }}</div>
          </div>
          <div>
            <span class="text-caption">{{ $t('number of vacation days') }}</span>
            <div class="font-weight-bold text-h5 mt-2">{{ financeYear?.nof_holiday_days }}</div>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-10"></v-divider>

      <v-row align="center">
        <v-col cols="12" class="text-body-2 mt-5">{{ $t('current year') }}</v-col>
        <v-col cols="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :pageData="pageData">
          </DynamicTable>
        </v-col>

      </v-row>
    </v-container>
    <FinancialYeaCancellationDialog :openDialog="cancellationDialog" :closeDialog="() => cancellationDialog = false" :financeYear="financeYear" />
  </section>
</template>

<script>
import FinancialYeaCancellationDialog from './FinancialYeaCancellationDialog.vue';
export default {
  name: "FinancialYearEntity",

  data: () => ({
    pageData: {
      screen_code: "11-001",
      url: null,
      controlRoute: "settings_hr/financial_year-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    openForm: false,
    valid: false,
    loading: false,
    financeYear: {},
    cancellationDialog: false,
    form: {},
    refresher: 0,
  }),
  components: {
    FinancialYeaCancellationDialog
  },
  computed: {
    dateInfo() {
      return (DATE) => {
        let date = new Date(DATE)
        const letterDayOptions = { weekday: 'long', localeMatcher: 'best fit', timeZone: 'UTC', };

        const options = {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        };

        const monthLetterOptions = {
          month: "long", // Use "long" style for full month names
        };

        const formatter = new Intl.DateTimeFormat(this.$i18n.locale, letterDayOptions);
        const formattedDateLetterDay = formatter.format(date);

        const formattedMonthLetterDate = new Intl.DateTimeFormat(this.$i18n.locale, monthLetterOptions).format(date);
        // eslint-disable-next-line no-unused-vars
        const { month: letterMonth } = formattedMonthLetterDate.split('/');

        const formattedDate = new Intl.DateTimeFormat(this.$i18n.locale, options).format(date);
        const [day, month, year] = formattedDate.split('/').map(item=> item);

        return {
          letterDay: formattedDateLetterDay,
          letterMonth: formattedMonthLetterDate,
          day: day,
          month: month,
          year: year
        };
      }

    },

  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;

      this.pageData.tableHeader = [
        { text: "year", key: "finance_year", type: 'text', classes: "" },
        { text: "closing date", key: "finance_year_closing_date", type: 'text', classes: "" },
      ]
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.refresher++
        this.$api.GET_METHOD(`finance_year${this.pageData.queryParam}`).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            console.log(response.data);
            this.pageData.rows = response.data.items.data;
            this.financeYear = response.data.current_finance_year;
            response.data.items.data.forEach((item) => {
              item.finance_year = `${item.finance_year_from} - ${item.finance_year_to}`
            })
            this.pageData.page = response.data.items.current_page;
            this.pageData.pagination = {
              page: response.data.items.current_page,
              totalPages: response.data.items.last_page,
              per_page: response.data.items.per_page,
              totalRows: response.data.items.total,
            };

          }
        });
      } else {
        this.$router.push("/");
      }
    },
    edit() {
      this.$router.push(`/${this.pageData.controlRoute}/${this.financeYear.id}`);
    },
  },
  props: {

  }
};
</script>

<style lang="scss" scoped>
span.card_year {
  display: flex;
  min-width: 122px;
  border: 1px solid #BFC4CB;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
</style>
